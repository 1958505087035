import {STRUCTURE} from "../../../consts/structure";
import PageHeader from "../../../components/PageHeader";
import React from "react";
import PageContent from "../../../components/PageContent";
import SimilarProduct from "../../../components/SimilarProduct";

const GroundSmartPro = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.SERVICES.GROUND_SMART_PV.NAME}/>

      <PageContent>
        <div className="project-details__item">
          <div className="row g-4 text-center mb-70">
            <h4 className="display-6">To usługa przeznaczona dla farm fotowoltaicznych oraz innych rozwiązań
              magazynowania energii
              w celach handlowych oraz technicznych związanych ze stabilizacją sieci energetycznej</h4>
          </div>
          <div className="row g-4">
            <div className="col-lg-7">
              <p className="mb-10 text-justify">
                Instalując nasz moduł GroundSmart Gateway w wersji Pro w ramach instalacji magazynu energii oraz
                obsługujących ich
                falowników hybrydowych, zyskują Państwo możliwość sterowania przepływem energii w ramach całego układu
                w celu uzyskania
                optymalnego jej wykorzystania handlowego, czy stabilizacji sieci energetycznej.<br/>
                Podobnie, jak w wersji SOHO, czy business również tutaj użytkownik otrzymuje różne tryby pracy
                systemu.
              </p>
              <h3 className="mb-10">Tryb awaryjny/backup (UPS)</h3>
              <p className="mb-30 text-justify">
                W tym scenariuszu, system magazynowania energii GroundSmart jest zaprojektowany przede wszystkim do
                zapewnienia energii
                w przypadku awarii sieci zasiląjacej . Może to być szczególnie przydatne w obszarach podatnych na
                częste przerwy w
                dostawie prądu lub ekstremalne warunki pogodowe. System ładuje magazyny, gdy tylko prąd jest dostępny
                i trzyma pełną
                pojemność na wypadek awarii. Szczególną opcją jest też możliwość ładowania magazynu wtedy, gdy cena
                energii jest
                najniższa.

              </p>
              <h3 className="mb-10">Tryb stabilizacji sieci</h3>
              <p className="mb-30 text-justify">
                System magazynowania energii GroundSmart współpracuje z lokalną siecią energetyczną, aby pomagać w
                stabilizacji sieci
                poprzez przechowywanie nadmiaru energii i dostarczanie jej w czasie wysokiego zapotrzebowania. Może to
                również obejmować
                sprzedaż nadmiaru wyprodukowanej energii z powrotem do sieci. Ten tryb wykorzystywany jest przeważnie
                w dużych
                komercyjnych magazynach energii.

              </p>
              <h3 className="mb-10">Tryb maksymalnego portfela</h3>
              <p className="mb-30 text-justify">
                W GroundSmart ten tryb pracy jest używany do maksymalizacji przychodów ze sprzedaży energii poprzez
                magazynowanie energii
                elektrycznej w godzinach, kiedy jest ona tańsza wg priorytetu PV/ cena z sieci (np. w nocy) i
                wykorzystywania jej całej
                podczas godzin szczytu, kiedy ceny energii są wyższe. Pozwala to maksymalizować cenę energii oddanej
                do sieci i gromadzić
                maksymalną wartość na swoim koncie rozliczeniowym u dostawcy energii.
              </p>
              <div className="image">
                <img src={require('./../../../assets/images/diagram/gs-pro-diagram.png')} alt="image"/>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="image mb-50">
                <img className="radius10" src={require('./../../../assets/images/service/house-pv-panels.jpg')}
                     alt="image"/>
              </div>
              <SimilarProduct product={STRUCTURE.SERVICES.GROUND_SMART_SOHO}/>
              <SimilarProduct product={STRUCTURE.SERVICES.GROUND_SMART_BUSINESS}/>
            </div>
          </div>
        </div>
      </PageContent>
    </main>
  );
};

export default GroundSmartPro;