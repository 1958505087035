import ReactDOM from "react-dom/client";
import {BrowserRouter, Routes, Route} from "react-router-dom";

import './assets/css/bootstrap.min.css';

import './assets/css/all.min.css';
import './assets/css/meanmenu.css';
import './assets/css/swiper-bundle.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/animate.css';
import './assets/css/nice-select.css';
import './assets/css/style.css';
import './css/apploader.css';

import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import GroundSmartGateway from "./pages/Products/GroundSmartGateway";
import GroundSmartSoho from "./pages/Services/GroundSmartSoho";
import GroundSmartBusiness from "./pages/Services/GroundSmartBusiness";
import GroundSmartPro from "./pages/Services/GroundSmartPro";
import About from "./pages/About";
import Faq from "./pages/KnowledgeBase/Faq";
import Youtube from "./pages/KnowledgeBase/Youtube";
import SystemConcept from "./pages/SystemConcept";
import EnergyStorages from "./pages/Products/EnergyStorages";
import EnergyStoragesHome from "./pages/Products/EnergyStorages/Home";
import EnergyStoragesIndustrial from "./pages/Products/EnergyStorages/Industrial";
import EnergyStoragesLargeScale from "./pages/Products/EnergyStorages/LargeScale";
import GroundCloudAPI from "./pages/Products/GroundCloudAPI";
import GroundSmartLink from "./pages/Products/GroundSmartLink";
import GroundHV2500 from "./pages/Products/GroundHV-2500";
import GH012662 from "./pages/Products/EnergyStorages/Home/GSHO-2662";
import GH025324 from "./pages/Products/EnergyStorages/Home/GSHO2-5324";
import HRH5200 from "./pages/Products/EnergyStorages/Industrial/HRH-5200";
import LFPWall10KV2 from "./pages/Products/EnergyStorages/Home/LFPWall-10K-V2";
import LFPWall2500 from "./pages/Products/EnergyStorages/Home/LFPWall-2500";
import LFPR51B100LV1 from "./pages/Products/EnergyStorages/Industrial/GS-51B100L";
import BCH100230 from "./pages/Products/EnergyStorages/Industrial/BCH-100230";
import Enerlution from "./pages/Products/Enerlution";
import GSMESS1 from "./pages/Products/EnergyStorages/Home/GS-MESS1";
import LCESS from "./pages/Products/LCESS";
import LCESSII from "./pages/Products/LCESSII";
import Products from "./pages/Products";
import LFPWall5000 from "./pages/Products/EnergyStorages/Home/LFPWall-5000";
import {STRUCTURE} from "./consts/structure";
import ChargingCars from "./pages/Products/ChargingCars";
import PhotovoltaicInstallations from "./pages/Products/PhotovoltaicInstallations";
import GSHO2662 from "./pages/Products/EnergyStorages/Home/GSHO-2662";
import GSHO25324 from "./pages/Products/EnergyStorages/Home/GSHO2-5324";
import GS51B100L from "./pages/Products/EnergyStorages/Industrial/GS-51B100L";
import ScrollToTop from "./hooks/ScrollToTop";

export default function App() {
  return (

    <BrowserRouter>
      <ScrollToTop/>
      <Routes>
        <Route element={<Layout/>}>
          <Route path="/" element={<Home/>}/>
          <Route path="/kontakt" element={<Contact/>}/>
          <Route path={STRUCTURE.ABOUT.PATH} element={<About/>}/>
          <Route path={STRUCTURE.SYSTEM_CONCEPT.PATH} element={<SystemConcept/>}/>
          <Route path={STRUCTURE.KNOWLEDGE_BASE.FAQ.PATH} element={<Faq/>}/>
          <Route path={STRUCTURE.KNOWLEDGE_BASE.YOUTUBE.PATH} element={<Youtube/>}/>
          <Route path={STRUCTURE.SERVICES.GROUND_SMART_SOHO.PATH} element={<GroundSmartSoho/>}/>
          <Route path={STRUCTURE.SERVICES.GROUND_SMART_BUSINESS.PATH} element={<GroundSmartBusiness/>}/>
          <Route path={STRUCTURE.SERVICES.GROUND_SMART_PV.PATH} element={<GroundSmartPro/>}/>
          <Route path={STRUCTURE.PRODUCTS.GROUND_LINK.PATH} element={<GroundSmartLink/>}/>
          <Route path={STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM.PATH} element={<GroundSmartGateway/>}/>
          <Route path={STRUCTURE.PRODUCTS.GROUND_CLOUD_API.PATH} element={<GroundCloudAPI/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.BASE.PATH} element={<EnergyStorages/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PATH} element={<EnergyStoragesHome/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.GS_MESS1.PATH} element={<GSMESS1/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.LFPWALL_2500.PATH} element={<LFPWall2500/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.LFPWALL_5000.PATH} element={<LFPWall5000/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.LFPWALL_10K_V2.PATH} element={<LFPWall10KV2/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.GSHO_2662.PATH} element={<GSHO2662/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.GSHO2_5324.PATH} element={<GSHO25324/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.INDUSTRIAL.PATH} element={<EnergyStoragesIndustrial/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.INDUSTRIAL.PRODUCTS.GS_51B100L.PATH}
                 element={<GS51B100L/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.INDUSTRIAL.PRODUCTS.HRH_5200.PATH}
                 element={<HRH5200/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.INDUSTRIAL.PRODUCTS.BCH_100230.PATH}
                 element={<BCH100230/>}/>
          <Route path={STRUCTURE.PRODUCTS.ENERGY_STORAGES.LARGE_SCALE.PATH} element={<EnergyStoragesLargeScale/>}/>
          <Route path={STRUCTURE.PRODUCTS.CHARGING_CARS.PATH} element={<ChargingCars/>}/>
          <Route path={STRUCTURE.PRODUCTS.PHOTOVOLTAIC_INSTALLATIONS.PATH} element={<PhotovoltaicInstallations/>}/>
          {/*<Route path="/produkty/ground-smart-gateway" element={<GroundSmartGateway/>}/>*/}
          <Route path={STRUCTURE.PRODUCTS.BASE.PATH} element={<Products/>}/>
          {/*<Route path={PATHS.PRODUCTS.GROUND_SMART_GATEWAY} element={<GroundSmartGateway/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.GROUND_SMART_CLOUD} element={<GroundSmartCloud/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.GROUND_SMART_BATTERY} element={<GroundSmartBattery/>}/>*/}

          {/*<Route path={PATHS.PRODUCTS.GROUND_HV_2500} element={<GroundHV2500/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.GH01_2662} element={<GH012662/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.GH02_5324} element={<GH025324/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.HRH_5200} element={<HRH5200/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.LFPWALL_10K_V2} element={<LFPWall10KV2/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.LFPWALL_5000} element={<LFPWall5000/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.LFPWALL_2500} element={<LFPWall2500/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.LFPR_51B100L_V1} element={<LFPR51B100LV1/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.BCH_100230} element={<BCH100230/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.ENERLUTION} element={<Enerlution/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.URA_MESS1} element={<URAMESS1/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.LCESS} element={<LCESS/>}/>*/}
          {/*<Route path={PATHS.PRODUCTS.LCESSII} element={<LCESSII/>}/>*/}
          {/*<Route path={PATHS.KNOWLEDGE_BASE.FAQ} element={<Faq/>}/>*/}
        </Route>
      </Routes>
    </BrowserRouter>

  );
}

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App/>);
