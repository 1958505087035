export const ABOUT_CARDS_CONTENT = [
  {
    title: 'Optymalizacja kosztów przez zarządzanie taryfami',
    content: 'GroundSmart Cloud to system inteligentnego zarządzania energią, który analizuje taryfy i warunki pogodowe, aby optymalizować czasy ładowania i wykorzystywania energii, obniżając rachunki za prąd.'
  },
  {
    title: 'Długoterminowa wydajność i oszczędności',
    content: 'Inwestycja w magazyny energii przynosi długoterminowe korzyści ekonomiczne i środowiskowe, zwiększając efektywność energetyczną i wspierając zrównoważony rozwój'
  },
  {
    title: 'Stabilizacja lokalnej sieci energetycznej',
    content: 'Inteligentne magazyny energii GroundSmart Cloud pomagają regulować obciążenie sieci, dostarczając energię w godzinach szczytu i gromadząc ją, gdy jest niższe zapotrzebowanie.',
  },
  {
    title: 'Zapewnienie ciągłości zasilania',
    content: 'W przypadku przerw w dostawie prądu, systemy GroundSmart i magazyny GroundWall mogą zapewnić tymczasowe zasilanie.',
  },
  {
    title: 'Automatyzacja i zdalne sterowanie',
    content: 'Systemy magazynowania energii wyposażone w oprogramowanie GroundSmart umożliwiają zdalne zarządzanie i monitoring, zwiększając komfort użytkowania.',
  },
  {
    title: 'Wsparcie dla odnawialnych źródeł energii',
    content: 'Magazyny energii integrują źródła odnawialne z siecią, magazynując nadwyżki i zapewniając dostępność energii, gdy produkcja jest niższa.',
  },
  {
    title: 'Zwiększenie niezależności energetycznej',
    content: 'Systemy magazynowania energii pozwalają domom na magazynowanie nadmiaru energii z paneli słonecznych, zwiększając samowystarczalność energetyczną.',
  },
  {
    title: 'Zmniejszenie śladu węglowego',
    content: 'Używanie energii z odnawialnych źródeł i przechowywanie jej w magazynach przyczynia się do redukcji emisji gazów cieplarnianych.',
  }
];