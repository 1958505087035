import {IParameter, IParameterGroup} from "../../types/product";

type IProps = {
  parameterGroup: IParameterGroup[];
  model: string;
}

const ProductParametersGrouped = ({parameterGroup, model}: IProps): JSX.Element => {

  const renderContent = (content: string | Array<string>) => {

    if (Array.isArray(content)) {
      return (
        <>
          <table>
            <tr>
              {content.map((item, index) => (
                <td key={index}>{item}</td>
              ))}
            </tr>
          </table>
        </>
      )
    }

    return (
      <>
        {content && (<td dangerouslySetInnerHTML={{__html: content}}/>)}
      </>
    )
  }

  return (
    <>
      <h2 className="mb-20">Product Parameter</h2>
      <table>
        <tr className="highlighted">
          <td><h5>Model</h5></td>
          <td>{model}</td>
        </tr>
        {parameterGroup.map((group, index) => (
          <>
            <tr key={index} className="highlighted-secondary">
              <td colSpan={2}><h5>{group.label}</h5></td>
            </tr>
            {group.parameters.map((parameter, index) => (
              <tr key={index}>
                <td><h5 dangerouslySetInnerHTML={{__html: parameter.label}}/></td>
                {renderContent(parameter.content)}
              </tr>
            ))}
          </>
        ))}
      </table>
    </>
  )
}

export default ProductParametersGrouped;