import {Link} from "react-router-dom";
import {STRUCTURE} from "../../../consts/structure";
import PageHeader from "../../../components/PageHeader";
import React from "react";
import PageContent from "../../../components/PageContent";
import SimilarProduct from "../../../components/SimilarProduct";

const GroundSmartSoho = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.SERVICES.GROUND_SMART_SOHO.NAME}/>

      <PageContent>
        <div className="project-details__item">
          <div className="row g-4 text-center mb-70">
            <h4 className="display-6">To usługa przeznaczona dla użytkowników prywatnych oraz mniejszych biur</h4>
          </div>
          <div className="row g-4">
            <div className="col-lg-7 ">
              <p className="mb-10 text-justify">
                Instalując nasz moduł GroundSmart Gateway we wspieranym falowniku hybrydowym otwierasz go na zupełnie
                nową jakość
                zarządzania optymalizacją przepływającej energii.
                W tej wersji oferujemy wsparcie dla domowych i biurowych systemów magazynowania energii oferując różne
                tryby
                pracy naszego systemu.
              </p>
              <h3 className="mb-10">Tryb samowystarczalności</h3>
              <p className="mb-30 text-justify">
                Dzięki GroundSmart Gateway, system zintegrowany z panelami słonecznymi i/lub turbinami wiatrowymi jest
                podłączony do naszej chmury GroundSmart Cloud.
                Energia jest magazynowana w bateriach domowych podczas słonecznego dnia (lub kiedy wiatr jest silny) i
                wykorzystywana w nocy lub podczas słabego
                nasłonecznienia. System jest zaprojektowany do minimalizacji zależności od sieci energetycznej –
                Maksymalizacja auto konsumpcji, tylko nadmiarowa
                energia (zadany % energii zmagazynowanej) jest oddawana do sieci, magazyn może być dodatkowo ładowany
                w nocy lub dni pochmurne,
                w czasie gdy cena energii jest niska.
              </p>
              <h3 className="mb-10">Tryb zarządzania szczytowym obciążeniem</h3>
              <p className="mb-30 text-justify">
                System magazynowania energii jest używany do redukcji kosztów energii poprzez magazynowanie energii
                elektrycznej w godzinach, kiedy jest ona tańsza
                wg. priorytetu PV cena z sieci (np. w nocy) i wykorzystywania jej podczas godzin szczytu, kiedy ceny
                energii są wyższe. Tzw. "peak shaving"
              </p>
              <h3 className="mb-10">Tryb awaryjny/backup (UPS)</h3>
              <p className="mb-30 text-justify">
                W tym scenariuszu, system magazynowania energii GroundSmart jest zaprojektowany przede wszystkim do
                zapewnienia energii w przypadku awarii
                sieci zasiląjacej . Może to być szczególnie przydatne w obszarach podatnych na częste przerwy w
                dostawie prądu lub ekstremalne warunki pogodowe.
                System ładuje magazyny, gdy tylko prąd jest dostępny i trzyma pełną pojemność na wypadek awarii.
                Szczególną opcją jest też możliwość ładowania
                magazynu wtedy, gdy cena energii jest najniższa.
              </p>
              <h3 className="mb-10">Tryb stabilizacji sieci</h3>
              <p className="mb-30 text-justify">
                System magazynowania energii GroundSmart współpracuje z lokalną siecią energetyczną, aby pomagać w
                stabilizacji sieci poprzez przechowywanie
                nadmiaru energii i dostarczanie jej w czasie wysokiego zapotrzebowania. Może to również obejmować
                sprzedaż nadmiaru wyprodukowanej energii
                z powrotem do sieci. Ten tryb wykorzystywany jest przeważnie w dużych komercyjnych magazynach energii.
              </p>
              <h3 className="mb-10">Tryb maksymalnego portfela</h3>
              <p className="mb-30 text-justify">
                W GroundSmart ten tryb pracy jest używany do maksymalizacji przychodów ze sprzedaży energii poprzez
                magazynowanie energii elektrycznej w godzinach,
                kiedy jest ona tańsza wg priorytetu PV/ cena z sieci (np. w nocy) i wykorzystywania jej całej podczas
                godzin szczytu, kiedy ceny energii są wyższe.
                Pozwala to maksymalizować cenę energii oddanej do sieci i gromadzić maksymalną wartość na swoim koncie
                rozliczeniowym u dostawcy energii.
              </p>
              <h3 className="mb-10">Tryb zintegrowany z domowym inteligentnym zarządzaniem energią<sup>*</sup></h3>
              <p className="mb-30 text-justify">
                System magazynowania jest częścią większego systemu zarządzania energią domu, który może obejmować
                inteligentne termostaty, oświetlenie,
                i inne urządzenia. System ten optymalizuje wykorzystanie energii w całym domu, zwiększając efektywność
                i redukując ogólne koszty energii,
                bazuje na profilu użytkownika opracowanym na podstawie danych prze model AI.<br/>
                <sup>*</sup><em><small>zależny od zastosowanego systemu automatyki domowej</small></em>
              </p>
              <div className="image">
                <img src={require('./../../../assets/images/diagram/gs-soho-diagram.png')} alt="image"/>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="image mb-50">
                <img src={require('./../../../assets/images/service/house-pv-panels.jpg')} className="radius10"
                     alt="image"/>
              </div>
              <SimilarProduct product={STRUCTURE.SERVICES.GROUND_SMART_BUSINESS}/>
              <SimilarProduct product={STRUCTURE.SERVICES.GROUND_SMART_PV}/>
            </div>
          </div>
        </div>
      </PageContent>
    </main>
  );
}

export default GroundSmartSoho;