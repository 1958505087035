import PageHeader from "../../../components/PageHeader";
import {Link} from "react-router-dom";
import {STRUCTURE} from "../../../consts/structure";
import SimilarProduct from "../../../components/SimilarProduct";

let PRODUCTS_LIST = [
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME,
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.INDUSTRIAL,
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.LARGE_SCALE
];

const EnergyStorages = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.PRODUCTS.ENERGY_STORAGES.BASE.NAME}/>
      <section className="service-area  ">
        <div className="container">
          <div className="service__wrp mt-80 pb-100">
            <div className="row g-4">
              {PRODUCTS_LIST.map((product, index) => (
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.15s">
                  <div className="service__item">

                    <div className="service__content">
                      <h4>
                        <Link to={product.PATH} className="text-white primary-hover">
                          {product.NAME}
                        </Link>
                      </h4>
                      <p className="text-white pt-10 pb-10">{product.DESCRIPTION}</p>
                      <div className="text-end">
                        <Link to={product.PATH} className="more-btn text-white mt-40">
                          Więcej<i className="fa-solid fa-arrow-right ml-10"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section className="project-details-area  pb-120">
        <div className="container">
          <div className="project-details__item">
            <div className="row g-4">
              <div className="col-lg-7">
                <h2 className="mb-20">Posiadamy w ofercie magazyny energii dla każdego segmentu naszych usług</h2>
                <p className="mb-10 text-justify">
                  Magazyn energii jest niezbędnym elementem naszego systemu. To on zapewnia możliwość magazynowania
                  nadwyżek energii do jej
                  późniejszego wykorzystania lub eksportowania do sieci energetycznej.
                  Posiadamy w swojej ofercie magazyny przeznaczone do współpracy z falownikami hybrydowymi
                  niskonapięciowymi jak i wysokonapięciowymi.<br/>
                </p>
                <h3 className="mb-10">Magazyny niskonapięciowe</h3>
                <p className="mb-30 text-justify">
                  Magazyn niskonapięciowy, pracujący na napięciach maksymalnie 48V stwarza mniejsze ryzyko ewentualnego
                  porażenia podczas instalacji lub uszkodzenia przewodów
                </p>
                <h3 className="mb-10">Magazyny wysokonapięciowe</h3>
                <p className="mb-30 text-justify">
                  Magazyn energii wysokonapięciowy wprawdzie pracuje na napięciach stałych rzędu kilkuset V, jednak
                  pozwala zastosować przewody połączeniowe
                  o dużo niższych przekrojach oraz umożliwia instalację w dużo większej odległości od falownika.
                </p>
                <h3 className="mb-10">Oferta dla rozwiązań SOHO - GROUND WALL 10K</h3>

                <div className="image-wrapper ">
                  <div className="image --shadow --small mt-40 mb-40">
                    <img src={require('./../../../assets/images/battery/ground-wall-2-5k.jpg')} className="radius10"
                         alt="image"/>
                  </div>
                </div>

                <h3 className="mb-10">Oferta dla rozwiązań SOHO - GROUND WALL 5K</h3>
                <div className="image-wrapper">

                  <div className="image --shadow --small mt-40 mb-40">
                    <img src={require('./../../../assets/images/battery/ground-wall-5k.jpg')} className="radius10"
                         alt="image"/>
                  </div>
                </div>

                <h3 className="mb-10">Oferta dla rozwiązań SOHO - GROUND WALL 2.5K</h3>
                <div className="image-wrapper">
                  <div className="image --shadow --small mt-40 mb-40">
                    <img src={require('./../../../assets/images/battery/ground-wall-10k.jpg')} className="radius10"
                         alt="image"/>
                  </div>
                </div>


                <h3 className="mb-10">Oferta dla rozwiązań SOHO - GROUND RACK 10K - 40K</h3>
                <div className="image-wrapper">
                  <div className="image --shadow --small mt-40 mb-40">
                    <img src={require('./../../../assets/images/battery/ground-rack-10k.png')} className="radius10"
                         alt="image"/>
                  </div>
                </div>

                <h3 className="mb-10">Oferta dla przemysłu i biznesu</h3>
                <p className="mb-30 text-justify">

                </p>
                <h3 className="mb-10">Oferta dla farm fotowoltaicznych i dużych instalacji</h3>
                <p className="mb-30 text-justify">

                </p>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-4">
                <div className="image mb-50">
                  <img src={require('./../../../assets/images/about/gs-desktop-project.jpg')} className="radius10"
                       alt="image"/>
                </div>
                <SimilarProduct product={STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM}/>
                <SimilarProduct product={STRUCTURE.PRODUCTS.GROUND_CLOUD_API}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>

  );
};

export default EnergyStorages;