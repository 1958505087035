import {Link} from "react-router-dom";
import {STRUCTURE} from "../../../consts/structure";
import PageHeader from "../../../components/PageHeader";
import React from "react";
import SimilarProduct from "../../../components/SimilarProduct";
import PageContent from "../../../components/PageContent";

const GroundCloudAPI = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.PRODUCTS.GROUND_CLOUD_API.NAME}/>

      <PageContent>
        <div className="project-details__item">
          <div className="row g-4">
            <div className="col-lg-7">
              <p className="mb-10 text-justify">
                Siła maszynowej inteligencji w ujarzmianiu strumienia energii
              </p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="image mb-50">
                <img src={require('./../../../assets/images/about/gs-desktop-project.jpg')} className="radius10"
                     alt="image"/>
              </div>
              <div className="service-two__item mb-50">
                <div className="icon">
                  <img src={require('./../../../assets/images/icon/service2.png')} alt="icon"/>
                </div>
                <div className="content mt-30">
                  <h4 className="mb-10">
                    <Link to={STRUCTURE.PRODUCTS.GROUND_LINK.PATH} className="primary-hover">
                      {STRUCTURE.PRODUCTS.GROUND_LINK.NAME}
                    </Link>
                  </h4>
                  <p>
                    {STRUCTURE.PRODUCTS.GROUND_LINK.DESCRIPTION}
                  </p>
                  <Link to={STRUCTURE.PRODUCTS.GROUND_LINK.PATH} className="btn-three mt-30">
                    Zobacz więcej<i className="fa-solid fa-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
              <div className="service-two__item">
                <SimilarProduct product={STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM}/>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </main>
  );
};

export default GroundCloudAPI;