import {STRUCTURE} from "../../../consts/structure";
import {Link} from "react-router-dom";
import SimilarProduct from "../../../components/SimilarProduct";
import PageHeader from "../../../components/PageHeader";
import PageContent from "../../../components/PageContent";

const GroundSmartSystem = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM.NAME}/>

      <PageContent>
        <div className="project-details__item">
          <div className="row g-4">
            <div className="col-lg-7">
              <h2 className="mb-20"></h2>
              <p className="mb-30 text-justify">
                ...
              </p>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="image mb-50">
                <img src={require('./../../../assets/images/about/gs-desktop-project.jpg')} className="radius10"
                     alt="image"/>
              </div>
              <SimilarProduct product={STRUCTURE.PRODUCTS.GROUND_CLOUD_API}/>
              <SimilarProduct product={STRUCTURE.PRODUCTS.GROUND_LINK}/>
            </div>
          </div>
        </div>
      </PageContent>
    </main>
  );
};

export default GroundSmartSystem;