type IProps = {
  items: string[];
}

const ListChecked = ({items}: IProps): JSX.Element => {
  return (
    <ul className="wow fadeInUp" data-wow-delay="0.45s">
      {items.map((item, index) => (
        <li><i className="fa-solid fa-check"></i> {item}</li>
      ))}
    </ul>
  );
}

export default ListChecked;