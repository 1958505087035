import PageHeader from "../PageHeader";
import ProductFeatures from "../ProductFeatures";
import ProductParameters from "../ProductParameters";
import ProductModels from "../ProductModels";
import {IProduct} from "../../types/product";
import ProductParametersGrouped from "../ProductParametersGrouped";
import ProductImage from "../ProductImage";

type IProps = {
  data: IProduct;
}

const Product = ({data}: IProps): JSX.Element => {
  return (
    <main>
      <PageHeader title={data.model}/>
      <section className="project-details-area pt-120 pb-120">
        <div className="container">
          <div className="project-details__item">
            <div className="row g-4">
              <h2 className="mb-20 text-center">{data.name}</h2>
              <p className="text-center">{data.description}</p>
              <div className="col-lg-7 mt-50 mb-50">
                {data.features && (<ProductFeatures features={data.features}/>)}
                {data.parameters && (<ProductParameters parameters={data.parameters} model={data.model}/>)}
                {data.parametersGrouped && (
                  <ProductParametersGrouped parameterGroup={data.parametersGrouped} model={data.model}/>)}
              </div>
              <div className="col-lg-5">
                {data.image && (<ProductImage url={data.image}/>)}
              </div>
              <div className="col-lg-12">

                <ProductModels models={data.models}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Product;