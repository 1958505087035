import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Outlet} from "react-router";

// @ts-ignore
const Layout = () => {
  return (
    <>
      <Header/>
      <Outlet/>
      <Footer/>
    </>
  );
}

export default Layout;