import React from "react";
import {STRUCTURE} from "../../consts/structure";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import Cards from "../../components/Cards";
import {ABOUT_CARDS_CONTENT} from "./const";


const About = () => {
  return (<main>
    <PageHeader title={STRUCTURE.ABOUT.NAME}/>

    <PageContent>
      <div className="project-details__item">
        <div className="row g-4">
          <div className="col-lg-6">
            <h2 className="mb-20">O nas</h2>
            <p className="mb-10 text-justify">
              Witamy w Ground Safety – miejscu, gdzie innowacja spotyka się z zieloną energią. Jako młoda i dynamiczna
              firma, specjalizujemy się w wdrażaniu zaawansowanych systemów EMS (Zarządzania Energia ze Źródeł
              Odnawialnych), mających na celu rewolucjonizację sposobu, w jaki myślimy o energii i jej wykorzystaniu.
              Nasz zespół składa się z doświadczonych inżynierów z ponad dziesięcioletnim stażem w branżach IT, IoT
              oraz automatyki przemysłowej, co czyni nas pionierami w dziedzinie nowoczesnych technologii
              energetycznych.
            </p>
            <p className="mb-30 text-justify">
              Naszą misją jest dostarczanie rozwiązań, które nie tylko przyczyniają się do ochrony środowiska poprzez
              promowanie energii odnawialnej, ale także oferują naszym klientom realne korzyści ekonomiczne. W tym
              celu stworzyliśmy flagowy produkt firmy – Ground Smart Gateway. To zaawansowane urządzenie, będące
              sercem naszego zintegrowanego systemu, umożliwia optymalizację kosztów energii poprzez inteligentne
              zarządzanie produkcją i zużyciem.
            </p>
            <h3 className="mb-10">Twoja przepustka do czystszego świata</h3>
            <p className="text-justify">
              Ground Smart Gateway, zainstalowany w falownikach PV różnych marek, łączy je z magazynami energii i
              integruje z Ground Smart Cloud – naszym chmurowym systemem informatycznym. System ten zbiera i
              przetwarza dane w czasie rzeczywistym, a dzięki wykorzystaniu algorytmów sztucznej inteligencji, jest w
              stanie sterować funkcjami poszczególnych urządzeń. To pozwala na efektywne zarządzanie cyklami ładowania
              i rozładowywania zgromadzonej energii, maksymalizując korzyści i efektywność energetyczną dla
              użytkowników końcowych.
            </p>
            <p className="mb-30 text-justify">
              W Ground Safety wierzymy, że przyszłość należy do zrównoważonego rozwoju i inteligentnych technologii.
              Nasze rozwiązania są krokiem w stronę tej wizji, zapewniając naszym klientom nie tylko oszczędności, ale
              także aktywny udział w tworzeniu lepszego jutra. Dołącz do nas w tej podróży ku zielonej rewolucji.
            </p>
          </div>
          <div className="col-lg-1">
          </div>
          <div className="col-lg-5">
            <div className="image">
              <img className="radius10" src={require('../../assets/images/about/team.jpg')} alt="image"/>
            </div>
          </div>
        </div>
      </div>

      <Cards cards={ABOUT_CARDS_CONTENT}/>
      <div className="row g-4">
        <div className="col-md-6">
          <div className="image">
            <img className="radius10" src={require('../../assets/images/about/data-center-green-630-400.jpg')}
                 alt="image"/>
          </div>
        </div>
        <div className="col-md-6">
          <div className="image">
            <img className="radius10" src={require('../../assets/images/about/gs-desktop-project.jpg')}
                 alt="image"/>
          </div>
        </div>
      </div>

    </PageContent>
  </main>);
};

export default About;