import {Link} from "react-router-dom";
import {STRUCTURE} from "../../consts/structure";
import ListChecked from "../../components/ListChecked";
import {HOME_ADVANTAGES, HOME_BOXES} from "./const";
import ProductBox from "../../components/ProductBox";

const Home = () => {
  return (
    <main>
      <section className="banner-area bg-image" data-background="assets/images/banner/banner-fp.jpg">
        <div className="banner__right">
          <img src={require('./../../assets/images/banner/hero.png')} alt="image"/>
        </div>
        <div className="container">
          <div className="banner__content">
            <h1 className="wow fadeInUp" data-wow-duration="1.15s" data-wow-delay="0.15s">
              Specjaliści od systemów inteligentnego<br/>
              wykorzystania <span className="primary-color">energii</span>
            </h1>
            <p className="wow fadeInUp" data-wow-duration="1.30s" data-wow-delay="0.30s">
              <strong className="primary-color">GroundSmart</strong> to kompleksowy <strong>inteligentny system
              zarządzania energią elektryczna</strong>
              umożliwiający optymalizację kosztu pozyskania energii
              i maksymalizację auto-konsumpcji
              lub przychodów z tytułu sprzedaży energii elektrycznej.
            </p>
            <Link to={STRUCTURE.ABOUT.PATH} className="btn-one mt-35 wow fadeInUp" data-wow-duration="1.45s"
                  data-wow-delay="0.45s">
              Więcej<i className="fa-solid fa-arrow-right ml-10"></i>
            </Link>
          </div>
        </div>
      </section>

      <section className="service-area  secondary-bg bg-image" data-background="assets/images/bg/service-bg.png">
        <div className="container">
          <h2 className="service__title text-white text-center text-capitalize">Pakiety</h2>
          <div className="service__wrp pb-100">
            <ProductBox products={HOME_BOXES}/>
          </div>
        </div>
      </section>

      <section className="about-area sub-bg pt-120">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6">
              <div className="about__image image">
                <img src={require('./../../assets/images/about/team-main2.jpg')} alt="image"/>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-header">
                <h5 className="wow fadeInUp" data-wow-delay="0.15s">Poznaj lepiej nasze produkty</h5>
                <h2 className="wow fadeInUp" data-wow-delay="0.30s">
                  Możesz lepiej wykorzystać energię elektryczną w&nbsp;swoim środowisku.
                </h2>
                <p className="wow fadeInUp" data-wow-delay="0.45s">
                  Wykorzystując ponad 10-letnie doświadczenie w dziedzinie projektowania systemów informatycznych,
                  automatyki oraz odnawialnych źródeł energii nasz zespół stworzył platformę dzięki której optymalizacja
                  kosztów wykorzystywania elektrycznej w różnych środowiskach stała się dostępna dla każdego.
                </p>
              </div>
              <div className="about__content ml-20">
                <ListChecked items={HOME_ADVANTAGES}/>
              </div>
              <div className="text-end">
                <Link to={STRUCTURE.ABOUT.PATH} className="btn-two mt-35 wow fadeInUp" data-wow-delay="0.45s">
                  Dowiedz się więcej<i className="fa-solid fa-arrow-right ml-10"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </main>);
};

export default Home;