export const STRUCTURE = {
  ABOUT: {
    PATH: '/o-firmie',
    NAME: 'O firmie',
    DESCRIPTION: '',
  },
  CONTACT: {
    PATH: '/kontakt',
    NAME: 'Kontakt',
    DESCRIPTION: '',
  },
  SYSTEM_CONCEPT: {
    PATH: '/koncepcja-systemu',
    NAME: 'Koncepcja systemu',
    DESCRIPTION: '',
  },
  PRIVACY_POLICY: {
    PATH: '/polityka-prywatnosci',
    NAME: 'Polityka prywatności',
    DESCRIPTION: '',
  },
  KNOWLEDGE_BASE: {
    BASE: {
      PATH: '/baza-wiedzy',
      NAME: 'Baza wiedzy',
      DESCRIPTION: '',
    },
    FAQ: {
      PATH: '/baza-wiedzy/faq',
      NAME: 'FAQ',
      DESCRIPTION: '',
    },
    YOUTUBE: {
      PATH: '/baza-wiedzy/nasz-ekspert-youtube',
      NAME: 'Nasz ekspert Youtube',
      DESCRIPTION: '',
    },
  },
  SERVICES: {
    BASE: {
      PATH: '/uslugi',
      NAME: 'Uslugi',
      DESCRIPTION: '',
    },
    GROUND_SMART_SOHO: {
      PATH: '/uslugi/ground-smart-soho',
      NAME: 'Ground Smart SOHO',
      DESCRIPTION: '',
    },
    GROUND_SMART_PV: {
      PATH: '/uslugi/ground-smart-pv',
      NAME: 'GroundSmart PV',
      DESCRIPTION: 'Siła maszynowej inteligencji w ujarzmianiu strumienia energii',
    },
    GROUND_SMART_BUSINESS: {
      PATH: '/uslugi/ground-smart-business',
      NAME: 'Ground Smart Business',
      DESCRIPTION: 'Twoja brama do optymalnego wykorzystania energii',
    },
  },
  PRODUCTS: {
    BASE: {
      PATH: '/produkty',
      NAME: 'Produkty',
      DESCRIPTION: 'Nasze produkty',
    },
    GROUND_LINK: {
      PATH: '/produkty/ground-link',
      NAME: 'Ground Link',
      DESCRIPTION: 'Twoja brama do optymalnego wykorzystania energii',
    },
    GROUND_SMART_SYSTEM: {
      PATH: '/produkty/ground-smart-system',
      NAME: 'Ground Smart System',
      DESCRIPTION: 'Twoja brama do optymalnego wykorzystania energii',
    },
    GROUND_CLOUD_API: {
      PATH: '/produkty/ground-cloud-api',
      NAME: 'Ground Cloud API',
      DESCRIPTION: 'Siła maszynowej inteligencji w ujarzmianiu strumienia energii',
    },
    ENERGY_STORAGES: {
      BASE: {
        PATH: '/produkty/magazyny-energii',
        NAME: 'Magazyny energii',
        DESCRIPTION: '',
      },
      HOME: {
        PATH: '/produkty/magazyny-energii/domowe',
        NAME: 'Domowe',
        DESCRIPTION: '',
        PRODUCTS: {
          GS_MESS1: {
            PATH: '/produkty/magazyny-energii/domowe/gs-mess1',
            NAME: 'GS-MESS1',
            DESCRIPTION: '',
          },
          LFPWALL_2500: {
            PATH: '/produkty/magazyny-energii/domowe/lfpwall-2500',
            NAME: 'LFPWall 2500',
            DESCRIPTION: '',
          },
          LFPWALL_5000: {
            PATH: '/produkty/magazyny-energii/domowe/lfpwall-5000',
            NAME: 'LFPWall 5000',
            DESCRIPTION: '',
          },
          LFPWALL_10K_V2: {
            PATH: '/produkty/magazyny-energii/domowe/lfpwall-10-v2',
            NAME: 'LFPWall 10k V2',
            DESCRIPTION: '',
          },
          GSHO_2662: {
            PATH: '/produkty/magazyny-energii/domowe/gsho-2662',
            NAME: 'GSHO 2662',
            DESCRIPTION: '',
          },
          GSHO2_5324: {
            PATH: '/produkty/magazyny-energii/domowe/gsho2-5324',
            NAME: 'GSHO2 5324',
            DESCRIPTION: '',
          },
        }
      },
      INDUSTRIAL: {
        PATH: '/produkty/magazyny-energii/do-zastosowan-przemyslowych',
        NAME: 'Do zastosowań przemysłowych',
        DESCRIPTION: '',
        PRODUCTS: {
          GS_51B100L: {
            PATH: '/produkty/magazyny-energii/przemyslowe/gs-51b100l',
            NAME: 'GroundRack w wersji Low voltage - GS-51B100L',
            DESCRIPTION: '',
          },
          HRH_5200: {
            PATH: '/produkty/magazyny-energii/przemyslowe/hrh-5200',
            NAME: 'High voltage HRH-5200',
            DESCRIPTION: '',
          },
          BCH_100230: {
            PATH: '/produkty/magazyny-energii/przemyslowe/bch-100230',
            NAME: 'BCH 100230',
            DESCRIPTION: '',
          },
        }
      },
      LARGE_SCALE: {
        PATH: '/produkty/magazyny-energii/wielkoskalowe',
        NAME: 'Wielkoskalowe',
        DESCRIPTION: '',
      }
    },
    CHARGING_CARS: {
      PATH: '/produkty/ladowanie-samochodow',
      NAME: 'Ładowanie samochodów',
      DESCRIPTION: '',
    },
    PHOTOVOLTAIC_INSTALLATIONS: {
      PATH: '/produkty/instalacje-fotowoltaiczne',
      NAME: 'Instalacje Fotowoltaiczne',
      DESCRIPTION: '',
    },

    // GROUND_HV_2500: '/produkty/ground-hv-2500',
    // GH01_2662: '/produkty/gh01-2662',
    // GH02_5324: '/produkty/gh02-5324',
    // HRH_5200: '/produkty/hrh-5200',
    // LFPWALL_10K_V2: '/produkty/lfpwall-10k-v2',
    // LFPWALL_5000: '/produkty/lfpwall-5000',
    // LFPWALL_2500: '/produkty/lfpwall-2500',
    // LFPR_51B100L_V1: '/produkty/lfpr-51B100L-v1',
    // BCH_100230: '/produkty/bch-100230',
    // ENERLUTION: '/produkty/enerlution',
    // URA_MESS1: '/produkty/ura-mess1',
    // LCESS: '/produkty/liquid-cooling-energy-storage-system',
    // LCESSII: '/produkty/liquid-cooling-energy-storage-system-II',
  }
}