import React from "react";
import {STRUCTURE} from "../../../consts/structure";
import PageHeader from "../../../components/PageHeader";
import PageContent from "../../../components/PageContent";

const Faq = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.KNOWLEDGE_BASE.YOUTUBE.NAME}/>

      <PageContent>
        <div className="row g-4">
          <div className="col-lg-12 mb-50">
            <h2 className="text-center">Poznaj naszego eskperta
              <a target="_blank" className="primary-color"
                 href={'https://www.youtube.com/@filipkaszubowski/videos'}>@filipkaszubowski</a>
            </h2>
          </div>
          <div className="col-lg-6">
            <iframe width="100%" height="400"
                    src="https://www.youtube.com/embed/r7pKMqr8gYU">
            </iframe>
          </div>
          <div className="col-lg-6">
            <iframe width="100%" height="400"
                    src="https://www.youtube.com/embed/_je6JFSDqvg">
            </iframe>
          </div>
        </div>
        <div className="row g-4 mt-2">
          <div className="col-lg-6">
            <iframe width="100%" height="400"
                    src="https://www.youtube.com/embed/_iJx0Df_Y0c">
            </iframe>
          </div>
          <div className="col-lg-6">
            <iframe width="100%" height="400"
                    src="https://www.youtube.com/embed/QueKd299ghI">
            </iframe>
          </div>
        </div>
      </PageContent>
    </main>
  );
};

export default Faq;