import PageHeader from "../../../components/PageHeader";
import {STRUCTURE} from "../../../consts/structure";

const PhotovoltaicInstallations = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.PRODUCTS.PHOTOVOLTAIC_INSTALLATIONS.NAME}/>
      <section className="service-area  ">
        <div className="container">
          <div className="service__wrp mt-80 pb-100">
            <div className="row g-4">

            </div>
          </div>
        </div>
      </section>
    </main>

  );
};

export default PhotovoltaicInstallations;