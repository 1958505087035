import {STRUCTURE} from "../../../consts/structure";
import PageHeader from "../../../components/PageHeader";
import React from "react";
import PageContent from "../../../components/PageContent";

const GroundSmartLink = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.PRODUCTS.GROUND_LINK.NAME}/>

      <PageContent>
        <div className="project-details__item">
          <div className="row g-4">
            <div className="col-lg-7">
              <h2 className="mb-20">Twoja brama do optymalnego wykorzystania energii</h2>
              <ul className="mb-30 text-justify">
                <li><i className="fa-solid fa-check"></i> Urządzenie klasy HEMS/EMS</li>
                <li><i className="fa-solid fa-check"></i> Sercem systemu jest wielordzeniowy procesor SOC</li>
                <li><i className="fa-solid fa-check"></i> Dwa złącza 485/MOD-BUS</li>
                <li><i className="fa-solid fa-check"></i> Sterowanie zewnętrznym sterownikiem</li>
                <li><i className="fa-solid fa-check"></i> Połączenie bezprzewodowe (WiFi) z chmurą GroundSmart Cloud
                </li>
                <li><i className="fa-solid fa-check"></i> Zaawansowane algorytmy kryptograficzne dla zachowania
                  najwyższych standardów bezpieczeństwa i prywatności przesyłania danych
                </li>
                <li><i className="fa-solid fa-check"></i> Wbudowany dwukierunkowy licznik energii z certyfikatem MID
                </li>
                <li><i className="fa-solid fa-check"></i> Dostęp przez www/aplikacja mobilna</li>
                <li><i className="fa-solid fa-check"></i> Integracja z systemami smart home</li>
              </ul>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="image mb-50">
                <img src={require('./../../../assets/images/about/gs-desktop-project.jpg')} className="radius10"
                     alt="image"/>
              </div>
            </div>
          </div>
        </div>
      </PageContent>
    </main>
  );
};

export default GroundSmartLink;