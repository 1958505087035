type IProps = {
  url: string;
}

const ProductImage = ({url}: IProps): JSX.Element => {
  console.log(url);
  return (
    <div className="text-center mt-100 position-sticky">
      <img src={require(`../../assets/products/${url}`)} alt="image"/>
    </div>
  );
}

export default ProductImage;