import React from 'react';
import {Link} from "react-router-dom";
import {STRUCTURE} from "../../consts/structure";

function Header() {
  return (
    <header className="header-area">
      <div className="container">
        <div className="header__main">
          <Link to="/" className={"logo"}>
            <img src={require('./../../assets/images/gslogo.png')} alt="logo"/>
          </Link>
          <div className="main-menu">
            <nav>
              <ul>
                <li>
                  <Link to={STRUCTURE.ABOUT.PATH}>{STRUCTURE.ABOUT.NAME}</Link>
                </li>
                <li>
                  <Link to={STRUCTURE.SYSTEM_CONCEPT.PATH}>{STRUCTURE.SYSTEM_CONCEPT.NAME}</Link>
                </li>
                <li>
                  <a href="#">{STRUCTURE.PRODUCTS.BASE.NAME} <i className="fa-solid fa-angle-down"></i></a>
                  <ul className="sub-menu">
                    <li>
                      <Link to={STRUCTURE.PRODUCTS.GROUND_LINK.PATH}>{STRUCTURE.PRODUCTS.GROUND_LINK.NAME}</Link>
                    </li>
                    <li>
                      <Link
                        to={STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM.PATH}>{STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM.NAME}</Link>
                    </li>
                    <li>
                      <Link
                        to={STRUCTURE.PRODUCTS.GROUND_CLOUD_API.PATH}>{STRUCTURE.PRODUCTS.GROUND_CLOUD_API.NAME}</Link>
                    </li>
                    <li>
                      <Link
                        to={STRUCTURE.PRODUCTS.ENERGY_STORAGES.BASE.PATH}>{STRUCTURE.PRODUCTS.ENERGY_STORAGES.BASE.NAME}</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link
                            to={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PATH}>{STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.NAME}</Link>
                        </li>
                        <li>
                          <Link
                            to={STRUCTURE.PRODUCTS.ENERGY_STORAGES.INDUSTRIAL.PATH}>{STRUCTURE.PRODUCTS.ENERGY_STORAGES.INDUSTRIAL.NAME}</Link>
                        </li>
                        <li>
                          <Link
                            to={STRUCTURE.PRODUCTS.ENERGY_STORAGES.LARGE_SCALE.PATH}>{STRUCTURE.PRODUCTS.ENERGY_STORAGES.LARGE_SCALE.NAME}</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={STRUCTURE.PRODUCTS.CHARGING_CARS.PATH}>{STRUCTURE.PRODUCTS.CHARGING_CARS.NAME}</Link>
                    </li>
                    <li>
                      <Link
                        to={STRUCTURE.PRODUCTS.PHOTOVOLTAIC_INSTALLATIONS.PATH}>{STRUCTURE.PRODUCTS.PHOTOVOLTAIC_INSTALLATIONS.NAME}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">{STRUCTURE.SERVICES.BASE.NAME} <i className="fa-solid fa-angle-down"></i></a>
                  <ul className="sub-menu">
                    <li>
                      <Link
                        to={STRUCTURE.SERVICES.GROUND_SMART_SOHO.PATH}>{STRUCTURE.SERVICES.GROUND_SMART_SOHO.NAME}</Link>
                    </li>
                    <li>
                      <Link
                        to={STRUCTURE.SERVICES.GROUND_SMART_BUSINESS.PATH}>{STRUCTURE.SERVICES.GROUND_SMART_BUSINESS.NAME}</Link>
                    </li>
                    <li>
                      <Link
                        to={STRUCTURE.SERVICES.GROUND_SMART_PV.PATH}>{STRUCTURE.SERVICES.GROUND_SMART_PV.NAME}</Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">{STRUCTURE.KNOWLEDGE_BASE.BASE.NAME} <i className="fa-solid fa-angle-down"></i></a>
                  <ul className="sub-menu">
                    <li>
                      <Link to={STRUCTURE.KNOWLEDGE_BASE.FAQ.PATH}>{STRUCTURE.KNOWLEDGE_BASE.FAQ.NAME}</Link>
                    </li>
                    <li>
                      <Link to={STRUCTURE.KNOWLEDGE_BASE.YOUTUBE.PATH}>{STRUCTURE.KNOWLEDGE_BASE.YOUTUBE.NAME}</Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to={STRUCTURE.CONTACT.PATH}>{STRUCTURE.CONTACT.NAME}</Link>
                </li>
              </ul>
            </nav>
          </div>
          <Link to="/contact" className="btn-two d-none d-lg-inline-block btn-right">
            Napisz do nas <i className="fa-solid fa-arrow-right ms-1"></i>
          </Link>
          <div className="bars d-block d-lg-none">
            <i id="openButton" className="fa-solid fa-bars"></i>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;