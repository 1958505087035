import PageHeader from "../../../../components/PageHeader";
import {STRUCTURE} from "../../../../consts/structure";
import {Link} from "react-router-dom";

let PRODUCTS_LIST = [
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.GS_MESS1,
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.LFPWALL_2500,
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.LFPWALL_5000,
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.LFPWALL_10K_V2,
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.GSHO_2662,
  STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.PRODUCTS.GSHO2_5324,
];


const EnergyStoragesHome = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.PRODUCTS.ENERGY_STORAGES.HOME.NAME}/>
      <section className="service-area  ">
        <div className="container">
          <div className="service__wrp mt-80 pb-100">
            <div className="row g-4">
              {PRODUCTS_LIST.map((product, index) => (
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.15s">
                  <div className="service__item">

                    <div className="service__content">
                      <h4>
                        <Link to={product.PATH} className="text-white primary-hover">
                          {product.NAME}
                        </Link>
                      </h4>
                      <p className="text-white pt-10 pb-10">{product.DESCRIPTION}</p>
                      <div className="text-end">
                        <Link to={product.PATH} className="more-btn text-white mt-40">
                          Więcej<i className="fa-solid fa-arrow-right ml-10"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </main>

  );
};

export default EnergyStoragesHome;