type IProps = {
  children: React.ReactNode;
}

const PageContent = ({children}: IProps): JSX.Element => {
  return (
    <section className="project-details-area pt-120 pb-120">
      <div className="container">
        {children}
      </div>
    </section>
  );
}

export default PageContent;