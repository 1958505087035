import {STRUCTURE} from "../../../consts/structure";
import PageHeader from "../../../components/PageHeader";
import React from "react";
import PageContent from "../../../components/PageContent";

const Faq = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.KNOWLEDGE_BASE.FAQ.NAME}/>

      <PageContent>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                 data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum et
                  est ut luctus. Etiam semper aliquet viverra. In hac habitasse platea dictumst.
                  Praesent tincidunt sagittis urna, id consequat magna efficitur id. Mauris velit
                  tortor, pretium a mollis eu, maximus eu magna. In non felis non nunc posuere
                  pellentesque. Aenean massa eros, vulputate eget urna vitae, pellentesque
                  facilisis erat. Cras interdum lorem rhoncus, varius tortor vitae, imperdiet est.
                </p>
                <p>
                  Nulla facilisi. Nunc sed diam at lectus posuere cursus. Nunc egestas imperdiet
                  sem nec ullamcorper. Aenean dictum lorem quis massa posuere, et vestibulum purus
                  feugiat. Vestibulum quis molestie turpis. Sed magna ex, pharetra id lacinia sed,
                  congue ut tortor. Nulla volutpat dictum ornare. Morbi tristique odio eget nunc
                  dapibus, id hendrerit nibh pulvinar. Fusce ut velit hendrerit, lobortis ligula
                  sit amet, consectetur massa. Sed eget purus ligula. In fermentum turpis vel elit
                  tempus, sed molestie ante interdum. Suspendisse pulvinar dapibus elit ac lobortis.
                  Pellentesque sit amet orci a orci accumsan molestie. Vestibulum ante ipsum primis
                  in faucibus orci luctus et ultrices posuere cubilia curae; Proin nec est erat.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo"
                 data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum et
                  est ut luctus. Etiam semper aliquet viverra. In hac habitasse platea dictumst.
                  Praesent tincidunt sagittis urna, id consequat magna efficitur id. Mauris velit
                  tortor, pretium a mollis eu, maximus eu magna. In non felis non nunc posuere
                  pellentesque. Aenean massa eros, vulputate eget urna vitae, pellentesque
                  facilisis erat. Cras interdum lorem rhoncus, varius tortor vitae, imperdiet est.
                </p>
                <p>
                  Nulla facilisi. Nunc sed diam at lectus posuere cursus. Nunc egestas imperdiet
                  sem nec ullamcorper. Aenean dictum lorem quis massa posuere, et vestibulum purus
                  feugiat. Vestibulum quis molestie turpis. Sed magna ex, pharetra id lacinia sed,
                  congue ut tortor. Nulla volutpat dictum ornare. Morbi tristique odio eget nunc
                  dapibus, id hendrerit nibh pulvinar. Fusce ut velit hendrerit, lobortis ligula
                  sit amet, consectetur massa. Sed eget purus ligula. In fermentum turpis vel elit
                  tempus, sed molestie ante interdum. Suspendisse pulvinar dapibus elit ac lobortis.
                  Pellentesque sit amet orci a orci accumsan molestie. Vestibulum ante ipsum primis
                  in faucibus orci luctus et ultrices posuere cubilia curae; Proin nec est erat.
                </p></div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree"
                 data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse elementum et
                  est ut luctus. Etiam semper aliquet viverra. In hac habitasse platea dictumst.
                  Praesent tincidunt sagittis urna, id consequat magna efficitur id. Mauris velit
                  tortor, pretium a mollis eu, maximus eu magna. In non felis non nunc posuere
                  pellentesque. Aenean massa eros, vulputate eget urna vitae, pellentesque
                  facilisis erat. Cras interdum lorem rhoncus, varius tortor vitae, imperdiet est.
                </p>
                <p>
                  Nulla facilisi. Nunc sed diam at lectus posuere cursus. Nunc egestas imperdiet
                  sem nec ullamcorper. Aenean dictum lorem quis massa posuere, et vestibulum purus
                  feugiat. Vestibulum quis molestie turpis. Sed magna ex, pharetra id lacinia sed,
                  congue ut tortor. Nulla volutpat dictum ornare. Morbi tristique odio eget nunc
                  dapibus, id hendrerit nibh pulvinar. Fusce ut velit hendrerit, lobortis ligula
                  sit amet, consectetur massa. Sed eget purus ligula. In fermentum turpis vel elit
                  tempus, sed molestie ante interdum. Suspendisse pulvinar dapibus elit ac lobortis.
                  Pellentesque sit amet orci a orci accumsan molestie. Vestibulum ante ipsum primis
                  in faucibus orci luctus et ultrices posuere cubilia curae; Proin nec est erat.
                </p>
              </div>
            </div>
          </div>
        </div>

      </PageContent>
    </main>
  );
};

export default Faq;