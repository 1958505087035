import {Link} from "react-router-dom";
import {STRUCTURE} from "../../../consts/structure";
import PageHeader from "../../../components/PageHeader";
import React from "react";
import PageContent from "../../../components/PageContent";
import SimilarProduct from "../../../components/SimilarProduct";

const GroundSmartBusiness = () => {
  return (
    <main>
      <PageHeader title={STRUCTURE.SERVICES.GROUND_SMART_BUSINESS.NAME}/>

      <PageContent>
        <div className="project-details__item">
          <div className="row g-4 text-center mb-70">
            <h4 className="display-6">To usługa przeznaczona dla użytkowników przemysłowych oraz innych dużych
              prosumentów wykorzystujących kontenerowe magazyny energii dużych pojemności</h4>
          </div>
          <div className="row g-4">
            <div className="col-lg-7 ">
              <h2 className="mb-20"></h2>
              <p className="mb-10 text-justify">
                Instalując nasz moduł GroundSmart Gateway w wersji Pro w ramach instalacji magazynu energii oraz
                obsługujących ich
                falowników hybrydowych, zyskują Państwo możliwość sterowania przepływem energii w ramach całego układu
                w celu uzyskania
                optymalnych warunków jej wykorzystania w kontekście kosztów, czy stabilizacji pracy urządzeń i sieci
                energetycznej.<br/>
                Podobnie, jak w wersji SOHO również tutaj użytkownik otrzymuje różne tryby pracy systemu.
              </p>
              <h3 className="mb-10">Tryb samowystarczalności</h3>
              <p className="mb-30 text-justify">
                W GroundSmart ten system jest zintegrowany z panelami słonecznymi i/lub turbinami wiatrowymi lub
                innymi źródłami
                energii OZE. Energia jest magazynowana w magazynie energii podczas słonecznego dnia (lub kiedy wiatr
                jest silny)
                i wykorzystywana w nocy lub podczas słabego nasłonecznienia. System jest zaprojektowany do
                minimalizacji zależności
                od sieci energetycznej – Max auto konsumpcji, tylko nadmiarowa energia ( zadany % zmagazynowanej) jest
                oddawana do
                sieci, magazyn może być dodatkowo ładowany, gdy cena energii w sieci zasilającej jest niska.
              </p>
              <h3 className="mb-10">Tryb zarządzania szczytowym obciążeniem</h3>
              <p className="mb-30 text-justify">
                System magazynowania energii jest używany do redukcji kosztów energii poprzez magazynowanie energii
                elektrycznej w godzinach,
                kiedy jest ona tańsza wg. priorytetu PV cena z sieci (np. w nocy) i wykorzystywania jej podczas godzin
                szczytu, kiedy ceny
                energii są wyższe. Tzw. tryb Peakshaving i przekracza chwilowo zapotrzebowanie zarezerwowane od
                dostawcy energii – możliwość
                uniknięcia kar za przekroczenia mocy.
              </p>
              <h3 className="mb-10">Tryb awaryjny/backup (UPS)</h3>
              <p className="mb-30 text-justify">
                W tym scenariuszu, system magazynowania energii GroundSmart jest zaprojektowany przede wszystkim do
                zapewnienia energii
                w przypadku awarii sieci zasiląjacej . Może to być szczególnie przydatne w obszarach podatnych na
                częste przerwy w
                dostawie prądu lub ekstremalne warunki pogodowe. System ładuje magazyny, gdy tylko prąd jest dostępny
                i trzyma pełną
                pojemność na wypadek awarii. Szczególną opcją jest też możliwość ładowania magazynu wtedy, gdy cena
                energii jest
                najniższa.

              </p>
              <h3 className="mb-10">Tryb zintegrowany z przemysłowym inteligentnym zarządzaniem energią</h3>
              <p className="mb-30 text-justify">
                System magazynowania jest częścią większego systemu zarządzania energią fabryki, który może obejmować
                inteligentne sterowanie
                obciążeniem , oświetleniem, i innymi urządzeniami. System ten optymalizuje wykorzystanie energii w
                całej fabryce, zwiększając
                efektywność i redukując ogólne koszty energii, bazuje na profilu pracy opracowanym na podstawie danych
                przez model AI.
              </p>
              <div className="image">
                <img src={require('./../../../assets/images/diagram/gs-business-diagram.png')} alt="image"/>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div className="image mb-50">
                <img src={require('./../../../assets/images/service/factory-pv.jpg')} className="radius10"
                     alt="image"/>
              </div>

              <SimilarProduct product={STRUCTURE.SERVICES.GROUND_SMART_SOHO}/>
              <SimilarProduct product={STRUCTURE.SERVICES.GROUND_SMART_PV}/>
            </div>
          </div>
        </div>
      </PageContent>
    </main>
  );
};

export default GroundSmartBusiness;