import React from "react";
import {STRUCTURE} from "../../consts/structure";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";

const SystemConcept = () => {
  return (<main>
    <PageHeader title={STRUCTURE.SYSTEM_CONCEPT.NAME}/>
    <PageContent>.</PageContent>
  </main>);
};

export default SystemConcept;