import {Link} from "react-router-dom";
import {IStructureProduct} from "../../types/structure";

type IProps = {
  products?: {
    item: IStructureProduct;
    icon: string;
  }[];
}

const ProductBox = ({products}: IProps): JSX.Element => {

  console.log(products);
  return (
    <div className="row g-4">
      {products?.map(product => (
        <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.15s">
          <div className="service__item">
            <div className="service__icon">
              <img src={require(`./../../assets/images/icon/service6.svg`).default} alt="icon"/>
            </div>
            <div className="service__content">
              <h4>
                <Link to={product.item.PATH} className="text-white primary-hover">
                  {product.item.NAME}
                </Link>
              </h4>
              <p className="text-white pt-10 pb-10">
                {product.item.DESCRIPTION}
              </p>
              <div className="text-end">
                <Link to={product.item.PATH} className="more-btn text-white mt-40">
                  Więcej<i className="fa-solid fa-arrow-right ml-10"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductBox;