import GroundHV7k from "../../assets/products/groundhv/GroundHV-7.5K.png";
import GroundHV10k from "../../assets/products/groundhv/GroundHV-10K.png";
import GroundHV12k from "../../assets/products/groundhv/GroundHV-12.5K.png";
import GroundHV15k from "../../assets/products/groundhv/GroundHV-15K.png";
import GroundHV17k from "../../assets/products/groundhv/GroundHV-17.5K.png";
import GroundHV20k from "../../assets/products/groundhv/GroundHV-20K.png";
import GH018K from "../../assets/products/gsho2662/GH01-8K.png";
import GH0110K from "../../assets/products/gsho2662/GH01-10K.png";
import GH0113K from "../../assets/products/gsho2662/GH01-13K.png";
import GH0115K from "../../assets/products/gsho2662/GH01-15K.png";
import GH0118K from "../../assets/products/gsho2662/GH01-18K.png";
import GH0121K from "../../assets/products/gsho2662/GH01-21K.png";

import GH0215K from "../../assets/products/gsho25324/GH02-15K.png";
import GH0221K from "../../assets/products/gsho25324/GH02-21K.png";
import GH0226K from "../../assets/products/gsho25324/GH02-26K.png";
import GH0231K from "../../assets/products/gsho25324/GH02-31K.png";
import GH0237K from "../../assets/products/gsho25324/GH02-37K.png";
import GH0242K from "../../assets/products/gsho25324/GH02-42K.png";

import {IModel} from "../../types/product";


type IProps = {
  models: IModel[];
}

const ProductModels = ({models}: IProps): JSX.Element => {

  const urlToImage = (image: string) => {
    switch (image) {
      case 'GroundHV-7.5K.png':
        return GroundHV7k;
      case 'GroundHV-10K.png':
        return GroundHV10k;
      case 'GroundHV-12.5K.png':
        return GroundHV12k;
      case 'GroundHV-15K.png':
        return GroundHV15k;
      case 'GroundHV-17.5K.png':
        return GroundHV17k;
      case 'GroundHV-20K.png':
        return GroundHV20k;
      case 'GH01-8K.png':
        return GH018K;
      case 'GH01-10K.png':
        return GH0110K;
      case 'GH01-13K.png':
        return GH0113K;
      case 'GH01-15K.png':
        return GH0115K;
      case 'GH01-18K.png':
        return GH0118K;
      case 'GH01-21K.png':
        return GH0121K;
      case 'GH02-15K.png':
        return GH0215K;
      case 'GH02-21K.png':
        return GH0221K;
      case 'GH02-26K.png':
        return GH0226K;
      case 'GH02-31K.png':
        return GH0231K;
      case 'GH02-37K.png':
        return GH0237K;
      case 'GH02-42K.png':
        return GH0242K;
      default:
        return '';
    }
  }

  return (
    <>
      <table>
        <thead>
        <tr>
          {models[0]?.name && (<th>Model</th>)}
          {models[0]?.voltage && (<th>Nominal Voltage</th>)}
          {models[0]?.connection && (<th>Connection</th>)}
          {models[0]?.voltageRange && (<th>Voltage Working Range</th>)}
          {models[0]?.capacity && (<th>Nominal Capacity</th>)}
          {models[0]?.weight && (<th>Weight</th>)}
          {models[0]?.dimension && (<th>Dimension(W*H*D)</th>)}
          {models[0]?.image && (<th></th>)}
        </tr>
        </thead>
        <tbody>
        {models.map((model, index) => {
          return (
            <tr key={index}>
              {model.name && (<td>{model.name}</td>)}
              {model.voltage && (<td>{model.voltage}</td>)}
              {model.connection && (<td>{model.connection}</td>)}
              {model.voltageRange && (<td>{model.voltageRange}</td>)}
              {model.capacity && (<td>{model.capacity}</td>)}
              {model.weight && (<td>{model.weight}</td>)}
              {model.dimension && (<td>{model.dimension}</td>)}
              {model.image && (<td><img src={urlToImage(model.image)} alt="image"/></td>)}
            </tr>
          )
        })}
        </tbody>
      </table>
    </>
  );
}

export default ProductModels;