type IProps = {
  title: string
}

const PageHeader = ({title}: IProps): JSX.Element => {
  return (
    <section className="page-banner bg-image pt-120 pb-120">
      <div className="container">
        <h2 className="wow fadeInUp" data-wow-duration="1.15s" data-wow-delay=".15s"
            dangerouslySetInnerHTML={{__html: title}}/>
      </div>
    </section>
  );
}

export default PageHeader;