import {Link} from "react-router-dom";

type IProps = {
  product: any;
}

const SimilarProduct = ({product}: IProps): JSX.Element => {
  return (
    <div className="service-two__item mb-50">
      <div className="icon">
        <img src={require('./../../assets/images/icon/service2.png')} alt="icon"/>
      </div>
      <div className="content mt-30">
        <h4 className="mb-10">
          <Link to={product.PATH} className="primary-hover">
            {product.NAME}
          </Link>
        </h4>
        <p>
          {product.DESCRIPTION}
        </p>
        <Link to={product.PATH} className="btn-three mt-30">
          Zobacz więcej<i className="fa-solid fa-arrow-right ms-1"></i>
        </Link>
      </div>
    </div>
  );
}

export default SimilarProduct;