import React from "react";

type Card = {
  title: string;
  content: string;
}

type IProps = {
  cards: Card[];
}

const Cards = ({cards}: IProps): JSX.Element => {
  return (
    <div className="project-details__item mt-40 mb-60">
      <div className="row g-4">
        {cards.map(card => (
          <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.15s">
            <div className="service-two__item --equal">
              <div className="content">
                <h4 className="mb-10">
                  {card.title}
                </h4>
                <p>
                  {card.content}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Cards;