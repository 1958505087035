import {STRUCTURE} from "../../consts/structure";

export const HOME_ADVANTAGES = [
  'Optymalizacja kosztów przez zarządzanie taryfami',
  'Zwiększenie niezależności energetycznej',
  'Stabilizacja lokalnej sieci energetycznej',
  'Wsparcie dla odnawialnych źródeł energii',
  'Zapewnienie ciągłości zasilania',
  'Automatyzacja i zdalne sterowanie',
  ',Zmniejszenie śladu węglowego',
  'Długoterminowa wydajność i oszczędności'
]
export const HOME_BOXES = [
  {
    item: STRUCTURE.SERVICES.GROUND_SMART_SOHO,
    icon: 'service1.svg'
  },
  {
    item: STRUCTURE.SERVICES.GROUND_SMART_BUSINESS,
    icon: 'service6.svg'
  },
  {
    item: STRUCTURE.SERVICES.GROUND_SMART_PV,
    icon: 'service1.svg'
  }
];