import React from 'react';
import {Link} from "react-router-dom";
import {STRUCTURE} from "../../consts/structure";

function Footer() {
  return (
    <footer className="footer-area footer-one secondary-bg">
      <div className="footer__bg">
        <img src={require('../../assets/images/bg/footer-bg2.png')} alt="image"/>
      </div>
      <div className="container">
        <div className="footer__wrp pt-40 pb-40">
          <div className="row g-4">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.15s">
              <div className="footer__item about">
                <div className="logo">
                  <Link className="d-block" to={'/'}>
                    <img src={require('./../../assets/images/gslogo.png')} alt="logo"/>
                  </Link>
                </div>
                <p className="mt-30 ">
                  <strong>Ground <span className="primary-color">Safety</span></strong>
                  to specjaliści od systemów inteligentnego wykorzystania energii.
                </p>
                <div className="social-icons mt-30">
                  <a href="https://www.facebook.com/" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
                  <a href="#0"><i className="fa-brands fa-twitter"></i></a>
                  <a href="#0"><i className="fa-brands fa-instagram"></i></a>
                  <a href="#0"><i className="fa-brands fa-linkedin-in"></i></a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 d-none d-lg-block"></div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.30s">
              <div className="footer__item service">
                <h4 className="text-white mb-30">{STRUCTURE.SERVICES.BASE.NAME} i {STRUCTURE.PRODUCTS.BASE.NAME}</h4>
                <ul>
                  <li className="mb-15">
                    <Link to={STRUCTURE.SERVICES.GROUND_SMART_SOHO.PATH}>
                      <i className="fa-solid fa-angle-right"></i> {STRUCTURE.SERVICES.GROUND_SMART_SOHO.NAME}
                    </Link>
                  </li>
                  <li className="mb-15">
                    <Link to={STRUCTURE.SERVICES.GROUND_SMART_BUSINESS.PATH}>
                      <i className="fa-solid fa-angle-right"></i> {STRUCTURE.SERVICES.GROUND_SMART_BUSINESS.NAME}
                    </Link>
                  </li>
                  <li className="mb-15">
                    <Link to={STRUCTURE.PRODUCTS.ENERGY_STORAGES.BASE.PATH}>
                      <i className="fa-solid fa-angle-right"></i> {STRUCTURE.PRODUCTS.ENERGY_STORAGES.BASE.NAME}
                    </Link>
                  </li>
                  <li className="mb-15">
                    <Link to={STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM.PATH}>
                      <i className="fa-solid fa-angle-right"></i> {STRUCTURE.PRODUCTS.GROUND_SMART_SYSTEM.NAME}
                    </Link>
                  </li>
                  <li className="mb-15">
                    <Link to={STRUCTURE.PRODUCTS.GROUND_CLOUD_API.PATH}>
                      <i className="fa-solid fa-angle-right"></i> {STRUCTURE.PRODUCTS.GROUND_CLOUD_API.NAME}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.60s">
              <div className="footer__item find">
                <h4 className="text-white mb-30">Znajdziesz nas pod adresem</h4>
                <ul>
                  <li className="mb-20">
                    <a href="#0">
                      <i className="fa-solid fa-circle pr-10"></i> 61-612 Poznań, ul. Rubież 46A <br/>
                      (Teren Poznańskiego Parku Naukowo Technologicznego)
                    </a>
                  </li>
                  <li className="mb-20">
                    <a href="#0">
                      <i className="fa-solid fa-circle pr-10"></i> (+48) 614 15 16 17
                    </a>
                  </li>
                  <li>
                    <a href="#0">
                      <i className="fa-solid fa-circle pr-10"></i> contact@ground-safety.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copy-text">
        <div className="container">
          <div className="d-flex copy-text align-items-center justify-content-between">
            <p className="text-center">&copy; <a href="/">Ground Safety</a> 2024 | Wszelkie prawa zastrzeżone.</p>
            <ul>
              <li>
                <Link to={STRUCTURE.PRIVACY_POLICY.PATH}
                      className="primary-hover">{STRUCTURE.PRIVACY_POLICY.NAME}</Link>
              </li>
              <li>
                <Link to={STRUCTURE.CONTACT.PATH} className="primary-hover">{STRUCTURE.CONTACT.NAME}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
