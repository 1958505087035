import {IParameter} from "../../types/product";

type IProps = {
  parameters: IParameter[];
  model: string;
}

const ProductParameters = ({parameters, model}: IProps): JSX.Element => {
  return (
    <>
      <h2 className="mb-20">Product Parameter</h2>
      <table className="mb-50">
        <tr className="highlighted">
          <td><h5>Model</h5></td>
          <td>{model}</td>
        </tr>
        {parameters.map((feature, index) => (
          <tr key={index}>
            {!feature.content && (<td colSpan={2}><h5>{feature.label}</h5></td>)}
            {feature.content && (<>
                <td><h5>{feature.label}</h5></td>
                <td dangerouslySetInnerHTML={{__html: feature.content}}></td>
              </>
            )}
          </tr>
        ))}
      </table>
    </>
  );
}

export default ProductParameters;