import {IFeature} from "../../types/product";
import cx from 'classnames';
import styles from './styles.module.scss';

type IProps = {
  features: IFeature[];
}

const ProductFeatures = ({features}: IProps): JSX.Element => {
  return (
    <>
      <h2 className="mb-20">Features</h2>
      <table className={cx(styles.c, 'table wow fadeInUp mb-50')} data-wow-delay="0.45s">
        {features.map((feature, index) => (
          <tr key={index} className={styles.c__item}>
            {feature.label && (<td className={styles.c__title}><h5>{feature.label}</h5></td>)}
            <td className={styles.c__description}>{feature.content}</td>
          </tr>
        ))}
      </table>
    </>
  );
}

export default ProductFeatures;